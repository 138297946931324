var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"EyL5xgKv_9t4Ujyw02JGN"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

import { config } from "./config/config";

if (process.env.NEXT_PUBLIC_ENABLE_SENTRY === "true") {
	Sentry.init({
		dsn: config.sentryKey,
		environment: config.env,
		normalizeDepth: 6,
		release: config.version,
		tracesSampler: () => Number(process.env.NEXT_PUBLIC_TRACE_SAMPLER || 0),
		debug: false,
		replaysOnErrorSampleRate: 1.0,
		replaysSessionSampleRate: 0.1
	});
}
